.order-view-new,
.invoice-view-new {
  @media screen and (max-width: 1000px) {
    max-height: 66vh;
  }
  * {
    font-family: $fontFancy2;
  }
  > div {
    padding: 20px;
  }
  .order-info {
    text-align: right;
    font-size: 14px;
    line-height: 150%;
    .order-info-receipt,
    .order-info-acceptance {
      display: grid;
      justify-content: right;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .retrieved {
    font-size: 15px;
    width: 160px;
    text-align: center;
    .retrieved-yes {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3p 9px;
      margin: 5px;
      border-radius: 5px;
      color: white;
      text-align: center;
      background: rgb(37, 189, 133);
      font-family: $fontFancy;
    }
  }
  .order-main {
    background: rgba(255, 255, 255, 0.699);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .order-number,
    .invoice-number {
      font-size: 40px;
      padding: 2px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-shadow: 2px 2px 1px #b0c4dea1;
    }
    .invoice-number {
      display: block;
    }
    .customer-name {
      padding: 2px 16px;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
      //background: whitesmoke;
      border-radius: 10px;
      margin-top: 8px;
      color: rgb(11, 84, 70);
      width: max-content;
      max-width: 500px;
    }
    .language-pair {
      display: grid;
      grid-template-columns: 1fr 0.5fr 1fr;
      align-items: center;
      > * {
        text-align: center;
      }
      margin: 5px 15px;
      font-size: 13px;
      img {
        width: 20px;
      }
    }
  }
  .price-data {
    font-family: $fontFancy;
    font-size: 12px;
    margin-top: 10px;
    > div {
      display: grid;
      grid-template-columns: 1fr 3fr;
      padding: 3px 12px;
      align-items: center;
      width: 290px;
      font-size: 12px;
      color: grey;
      :nth-child(2) {
        font-size: 18px;
        color: black;
      }
    }
  }
  .order-documents,
  .partial-values,
  .invoice-items {
    max-height: 50vh;
    padding: 0;
    overflow-y: auto;
  }
  .invoice-data {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      padding: 5px;
    }
    .invoice-caption {
      font-size: 12px;
      color: grey;
    }
  }
  .translator {
    padding: 3px 18px;
    background: rgb(190, 236, 243);
    width: max-content;
    font-size: 13px;
    border-radius: 5px;
    margin: 0 auto;
  }
  .remarks-full {
    font-size: 11px;
    height: 100%;
    max-width: 260px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  .universal-table {
    td {
      text-align: center;
      padding: 16px 2px;
    }
  }
  .minor-label {
    display: block;
    font-size: 9px;
    color: grey;
  }
}
.order-comment {
  position: absolute;
  top: -3px;
  left: 50%;
  translate: -50% 0;
  text-align: center;
  font-size: 9.5px;
  border-radius: 3px 3px 0 0;
  background: #4e7a7b;
  user-select: none;
  cursor: pointer;
  transition: 0.15s ease-in all;
  &:hover {
    scale: 1.1;
  }
  color: white;
  padding: 1px 5px;
  right: 0;
  width: fit-content;
}
