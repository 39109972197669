.search-box {
  position: relative;
  font-size: 13px;
  .input-divide {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 80px;
    align-items: center;
    grid-gap: 9px;
    box-sizing: border-box;
    button {
      height: 30px;
      border-radius: 10px;
    }
  }
}

.search-input,
.workTime .search-input {
  padding: 12px 10px;
  height: 40px;
  background: white;

  box-shadow: 0 0 1px;
  outline: none;
  box-sizing: border-box;
}

.filled-input-box {
  display: grid;
  grid-template-columns: 1fr 35px;
}

.filled-input-activate {
  width: 100%; 
  padding: 8px 12px;
  cursor:pointer;
  border-radius:12px;
  &:hover {
    background:white;
  }
  transition:.4s ease-in-out all;
}
.filled-input,
.workTime .filled-input {
  //cursor: pointer;
  padding: 0 5px;
  font-size: 14px;
  background: white;
  box-shadow: 0 0 1px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.32) inset;
  > div {
    width: 100%;
  }
  &:hover {
    //   background: rgb(235, 235, 235);
  }
  .filled-input-placeholder {
    padding: 0;//5px 16px 5px 5px;
    color: rgb(90, 120, 131);
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      padding: 5px 12px;
    }
  }
}

.search-box-results {
  animation-name: appear;
  animation-duration: 0.15s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  background: white;
  max-height: 180px;
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  width: 100%;
  min-width: 500px;
  top: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.13), 0 0 0.5px rgba(0, 0, 0, 0.1);

  > div {
    cursor: pointer;

    &:nth-child(2n + 1) {
      background: rgba(245, 245, 245, 0.61);
    }
    &:hover,
    &:nth-child(2n + 1):hover {
      background: rgb(207, 248, 241);
    }
  }
  .search-box-result-info {
    padding: 10px 12px;
  }
}

.order-species {
  .order-item {
    padding: 2px 2px;
    display: grid;
    grid-template-columns: 80px 1fr 1fr 35px;
    align-items: center;
    > div {
      padding: 2.5px 7px;
    }
    &.short {
      grid-template-columns: 80px 1fr 30px;
      background: white;
      margin-right: 15px;
      border-radius: 8px;
      padding: 2px 8px;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.52);
      cursor:pointer;
      .client {

        text-overflow: ellipsis;
        text-wrap: nowrap;
        width:96%;
        overflow:hidden;
        height:16px;  
      }
      > div {
        padding: 4px 7px;
      }
    }
    

    .doc-names {
      font-size: 12px;
      //font-style: italic;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      > div {
        font-size: 9px;
        > div {
          padding: 2px 5px;
          background: #e5f3efa3;
          font-size: 9.5px;
          margin-right: 3px;
          margin-bottom: 1px;
          border-radius: 2px;
        }
      }
    }

    .number {
      display: flex;
      align-items: center;
      font-weight: 500;
    }
    .language-pair {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      font-size: 10px;
      font-weight: bold;
    }
  }
}
