.order-monitor {
  //colors

  $o_back_color: black;
  $o_back_color2: rgb(20, 20, 20);
  $o_text_color: whitesmoke;
  $o_highlight_color: rgb(214, 214, 14);
  $o_scrollbar_color: whitesmoke grey;
  $o_scrollbar_back_color: rgb(58, 42, 42);
  $o_td_back: rgb(34, 34, 34);
  $o_gradient_back: radial-gradient(
    circle,
    rgb(10, 20, 18) 0%,
    rgb(5, 14, 17) 100%
  );
  $o_remarks_back: #200514d5;
  $o_tbody_background: linear-gradient(20deg, black, rgb(1, 3, 23));
  $o_tfoot_background: rgb(15, 17, 16);
  $o_tfoot_color: rgb(29, 195, 118);
  $o_customer_shadow: 1px 1px 2px black;
  $o_th_color: rgb(160, 160, 160);
  $o_tbody_shadow: 0 0 13px black;
  $o_translator_back: linear-gradient(45deg, #035061, #4073a0);
  $o_list_selected: linear-gradient(20deg, rgb(40, 98, 40), rgb(29, 44, 6));
  $o_list_done: 120px 0 150px -65px #0d5b58 inset;
  $o_list_selected_shadow: 0 0 30px rgba(0, 0, 0, 0.7);
  $o_remarks_color: magenta;
  $o_td_border: 0.5px solid rgb(23, 23, 23);
  $o_chars_back: linear-gradient(288deg, rgb(27, 55, 29), rgb(63, 131, 84));
  $o_scrollbar_track_back: black;
  $o_filters_shadow: 0 0 0 1px rgb(49, 49, 49) inset;
  $o_list_item_back: lighten($o_back_color, 5);
  $o_zwykle: #5affa5;
  $o_uwierzytelnione: #b95aff;
  $o_sum_custom: yellow;
  $o_flag_filter: saturate(0.3);
  @mixin o_options_img {
    img.invert {
      cursor: pointer;
      height: 24px;
      padding: 0px;
      margin-right: 13px;
      filter: invert(0.58);
      &:hover {
        filter: invert(1);
      }
    }
  }
  filter: brightness(0.96) contrast(1.12);
  $o_back_color: rgb(242, 242, 242);
  $o_back_color2: #ffffffb6 URL("./images/background/back_plaster.jpg");
  $o_text_color: rgb(15, 15, 15);
  $o_highlight_color: #00afca;
  $o_scrollbar_color: rgb(218, 218, 218) grey;
  $o_scrollbar_back_color: rgb(187, 218, 210);
  $o_td_back: rgb(246, 246, 246);
  $o_gradient_back: none;
  $o_remarks_back: #f4f9fad5;
  $o_remarks_color: rgb(67, 8, 67);
  $o_tbody_background: rgba(255, 255, 255, 0.862);
  $o_tfoot_background: rgb(246, 246, 246);
  $o_tfoot_color: rgb(17, 73, 47);
  $o_customer_shadow: none;
  $o_th_color: black;
  $o_tbody_shadow: 0 2px 12px rgba(0, 0, 0, 0.06);
  $o_translator_back: linear-gradient(45deg, #ecf5f7, #dbecfb);
  $o_list_selected: rgba(247, 252, 249, 1);
  $o_list_done: 0 0 300px 100px rgba(255, 255, 255, 0.94) inset;
  $o_list_selected_shadow: 0 0 36px rgba(0, 0, 0, 0.175);
  $o_td_border: 0.5px solid rgba(0, 0, 0, 0.06);
  $o_chars_back: linear-gradient(90deg, rgb(202, 251, 206), rgb(208, 249, 217));
  $o_scrollbar_track_back: rgb(233, 233, 233);
  $o_filters_shadow: 0 0 5px rgba(0, 0, 0, 0.23);
  $o_list_item_back: transparent;
  $o_zwykle: #1f8049;
  $o_uwierzytelnione: #906716;
  $o_sum_custom: red;
  $o_flag_filter: saturate(0) brightness(1.5) opacity(0.25);
  @mixin o_options_img {
    img {
      height: 24px;
      width: auto !important;
      margin-right: 9px;
    }
    img.invert {
      cursor: pointer;
      height: 27px;
      padding: 0px;
      margin-right: 9px;
      filter: invert(0.38);
      &:hover {
        filter: invert(0);
      }
    }
  }

  $mobileWidth: 760px;
  background: $o_back_color;
  //background:  url("../images/background/dark_back7.jpg") rgba(0, 0, 0, 0.926);
  //background-size:cover;
  //background-blend-mode:darken;
  * {
    font-family: $fontFancy2;
    color: $o_text_color;
  }
  @mixin center {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  @mixin scrollbar {
    &::-webkit-scrollbar {
      width: 5px;
      height: 9px;
    }

    scrollbar-color: $o_scrollbar_color;

    &::-webkit-scrollbar-track {
      background: $o_scrollbar_track_back;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $o_scrollbar_back_color;
      border-radius: 5px;
      //border: 1px solid red;
      border: none;
    }
  }

  .order-monitor-screen {
    display: grid;
    grid-template-columns: 300px 1fr;
    min-height: 100vh;
    min-width: 100vw;
    @media screen and (max-width: $mobileWidth) {
      grid-template-columns: 1fr;
      grid-template-rows: 200px 1fr;
    }
  }
  .order-monitor-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
  }
  .order-monitor-list-top {
    display: grid;
    grid-template-rows: 54px 1fr;
    max-height: 100vh;
    .order-monitor-list-filters,
    .order-monitor-list-flags {
      display: flex;
    }
    .order-monitor-filter-info {
      color: #7d0000;
      font-size: 15px;
      text-align: center;
      background: #fcd0f6;
      padding: 1px;
    }
    .order-monitor-list-filters {
      box-shadow: $o_filters_shadow;
      border-radius: 5px;
      align-items: center;

      img,
      .img {
        height: 25px;

        padding: 1px;
        margin: 0 2px;
        border-radius: 5px;
        transform-origin: center;
        transition: 0.1s ease-in-out all;
        filter: $o_flag_filter;
        cursor: pointer;
        &:hover {
          scale: 1.05;
        }
        &.active {
          position: relative;
          filter: none;
        }
      }
    }
    img.imgHistory {
      //filter: invert(0.2);
      padding: 1px 0px;
      margin-top: 7px;
      height: 18px;
      &.active {
        filter: invert(0.5);
      }
    }
    .order-monitor-list-search {
      background: transparent;
      &[value=""] {
        font-size: 13px;
      }
      font-size: 17px;
      padding: 7px 3px;
      outline: none;
      border: none;
      max-width: 175px;
    }
    @media screen and (max-width: $mobileWidth) {
      grid-template-rows: 75px 1fr;
      .order-monitor-list-filters {
        display: grid;
        width: max-content;
        max-width: 100%;
        grid-template-columns: 120px 1fr 50px;
        margin-top: 5px;
        img,
        .img {
          height: 35px;
          padding: 2px 3px;
        }
      }
      .order-monitor-list-search {
        max-width: 100%;
      }
    }
  }
  .order-monitor-list {
    margin-bottom: 16px;
    padding: 0 8px;
    font-size: 18px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    @include scrollbar;
    @media screen and (max-width: $mobileWidth) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-y: hidden;
      overflow-x: auto;
      > * {
        min-width: 160px;
      }
    }
    .monitor-list-separate {
      margin-top: 50px;
      padding: 5px;
      color: grey;
      @media screen and (max-width: $mobileWidth) {
        margin-top: 0;
        margin-left: 70px;
        max-width: max-content;
        min-width: 130px;
        text-align: right;
      }
    }
    .order-monitor-list-single {
      padding: 12px;
      border-radius: 10px;
      margin: 9px 0;
      transition: 0.12s ease-in-out all;
      background: $o_list_item_back;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.12);
      display: grid;
      grid-template-columns: 1fr 70px;
      grid-gap: 10px;
      position:relative;
      &:nth-child(2n + 1) {
        //background: lighten($o_back_color, 10);
      }
      cursor: pointer;

      &:hover {
        background: lighten($o_back_color, 30);
      }
      opacity: 0.86;
      &.selected {
        position: sticky;
        top: 0;
        z-index: 2;
        background: $o_list_selected;
        scale: 1.1;
        box-shadow: $o_list_selected_shadow;
        opacity: 1;
        &:first-child {
          margin-top: 4px;
        }
        border-radius: 0;
      }
      &.allDone {
        //background: rgb(5, 81, 80);
        opacity: 1;
        box-shadow: $o_list_done;
        border: 1px solid rgb(44, 237, 205);
        border-left: 12px solid rgb(44, 237, 205);

        border-radius: 3px 3px 3px 3px;
      }
      &.ready {
        opacity: 0.5;
        //scale: 0.9;
        background: none;
        padding: 2px 5px;
        margin: 1px;
        transform-origin: left;
        position: relative;
        &.selected {
          scale: 1;
        }
        &::after {
          content: "✓";
          left: calc(90% + 5px);
          position: absolute;
          font-size: 20px;
        }
        box-shadow: unset;
        .order-monitor-list-due {
          display: none;
        }
      }
      .order-monitor-list-customer {
        color: $o_highlight_color;
        font-size: 14px;
        margin-top: 4px;
      }
      .order-monitor-list-due {
        font-size: 16px;
        text-align: right;
        :first-child {
          font-size: 13px;
        }
      }
      @media screen and (max-width: $mobileWidth) {
        min-width: 300px;
        margin: 0 10px;
      }
    }
  }

  .order-monitor-details {
    padding: 0;
    font-size: 34px;
    background: #d6d6d6;
    max-height: 100vh;
    overflow: hidden;
    @media screen and (max-width: $mobileWidth) {
      max-height: calc(100vh - 260px);
      overflow-y: auto;
    }
  }
  .pap-words {
    margin-top: 5px;
    border-radius: 5px;
    background: white;
    color: black;
    font-size: 14px;
    padding: 5px;
  }
  .order-monitor-sums {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    .order-monitor-sums-price,
    .order-monitor-sums-price-calc {
      .order-monitor-sums-net,
      .order-monitor-sums-gross {
        display: grid;
        align-items: center;
        width: 250px;
        text-align: right;
        grid-template-columns: 40px 1fr;
        > :first-child {
          font-size: 15px;
          color: grey;
        }
      }
      .order-monitor-sums-net {
        font-size: 60%;
      }
    }
    .order-monitor-sums-price-calc {
      .order-monitor-sums-net,
      .order-monitor-sums-gross {
        > *,
        > * span {
          color: $o_sum_custom;
        }
      }
    }
    .order-monitor-options {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;
      padding: 5px 0;
      @include o_options_img;
    }
  }
  .order-monitor-customer {
    .order-monitor-customer-inner {
      margin-bottom:5px;
      color: $o_highlight_color;
      text-shadow: $o_customer_shadow;
      font-size: 30px;

      @media screen and (max-width: $mobileWidth) {
        font-size: 20px;
        margin-top: 5px;
      }
    }
  }
  .order-monitor-due {
    font-size: 38px;
    @media screen and (max-width: $mobileWidth) {
      font-size: 16px;
    }
  }
  .order-monitor-remarks-frame:not(:empty) {
    margin-top: 13px;
    display:flex;
    justify-content: space-between;
  }
  .order-monitor-remarks {
    &:empty {
      display: none;
    }
    margin-top: 2px;
    font-size: 13px;
    color: $o_remarks_color;
    padding: 12px;
    background: $o_remarks_back;
    border-radius: 10px;
    width: 100%;
    max-width: max-content;
  }
  .order-monitor-acceptance {
    color: $o_highlight_color;
    font-size: 20px;
    text-align: right;
    @media screen and (max-width: $mobileWidth) {
      text-align: left;
    }
  }
  .order-monitor-single-wrap {
    @include center;
    height: 100vh;
    padding: 15px;
  }
  .order-monitor-single-wrap-frame {
    box-sizing: border-box;
    padding: 30px;
    background: $o_back_color2;
    background-blend-mode: lighten;
    // border: 1px solid grey;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    //background: rgba(0, 0, 0, 0.526) url("../images/background/dark_back8.jpg");
    //background-size:cover;
    //background-blend-mode:darken;
  }
  .order-monitor-single {
    max-height: 95vh;
    min-height: 95vh;

    display: grid;
    grid-template-rows: max-content 1fr 150px;
    @media screen and (max-width: $mobileWidth) {
      max-width: 95vw;
      min-height: 100%;
      max-height: 100%;
      font-size: 15px;
    }
    .order-monitor-single-main {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: $mobileWidth) {
        display: block;
      }
    }
    .order-monitor-single-order {
      font-size: 50px;
      @media screen and (max-width: $mobileWidth) {
        font-size: 40px;
      }
    }
    .order-monitor-single-documents {
      margin-top: 24px;
      font-size: 17.5px;
      max-height: 60vh;
      overflow-y: auto;
      @include scrollbar;
    }
    .order-monitor-doc-table {
      border-collapse: collapse;
      width: 100%;
      font-size: 15px;
      border-radius: 6px;
      overflow: hidden;

      thead {
        position: sticky;
        top: -1px;
        z-index: 2;
      }
      tbody {
        background: $o_tbody_background;
        box-shadow: $o_tbody_shadow;
      }
      tr {
        animation-name: cropUp;
        animation-duration: 0.25s;
        animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        animation-fill-mode: both;
      }
      th,
      td {
        border: $o_td_border;
        // border: 0.5px solid rgb(92, 92, 92);
        padding: 10px 5px;
        position: relative;

        text-align: center;
        .inputPages {
          background: none;
          color: orange;
          width: 85%;
          text-align: center;
          font-size: 18px;
          padding: 0;
          margin: 0;
          border: 1px solid;
          border-radius: 5px;
        }
      }
      th {
        background: $o_td_back;
        font-size: 10px;
        color: $o_th_color;
        font-weight: 100;
      }
      tfoot {
        th {
          background: $o_tfoot_background;
          color: $o_tfoot_color;
          font-size: 15px;
          &.draft {
            color: rgb(84, 84, 84);
          }
        }
        td:empty {
          background: none;
          border: none;
        }
      }
      .order-monitor-doc-translator {
        width: 160px;
        font-size: 12px;

        border-radius: 5px;
        margin: 2px auto;
        padding: 5px 0;
        &.translation {
          background: $o_translator_back;
          font-weight: 700;
        }
        &.non-translation {
          background: none;
          color: grey;
        }
        &.external {
          background:pink;
          color:black;
        }
      }
    }
  }
  .filter-not-found {
    animation-name: appear;
    animation-duration: 1s;
    animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    animation-fill-mode: both;
    padding: 30px 70px;
    background: $o_gradient_back;
    box-sizing: border-box;
    height: calc(100% - 20px);
    border-radius: 10px;
    margin: 10px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      color: rgb(161, 161, 161) !important;
    }
    text-align: center;
    font-size: 18px;
    > div {
      animation-name: jumpIn;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;
    }
    img {
      width: 90px;
      padding: 10px;
      filter: invert(0.7);
    }
  }
  @media screen and (max-width: $mobileWidth) {
    .order-monitor-single-wrap {
      padding: 0 4px;
      .order-monitor-single-wrap-frame {
        padding: 0;
        background: transparent;
      }
    }
    .order-monitor-single {
      grid-template-rows: max-content 30vh 200px;
      .order-monitor-single-documents {
        margin-top: 5px;
        font-size: 13px;
        max-height: 30vh;
        overflow-y: auto;
        max-width: 100%;
      }
      .order-monitor-doc-table {
        td,
        th {
          padding: 0;
        }
        .order-monitor-doc-translator {
          width: auto;
          font-size: 11px;
          border-radius: 5px;
          margin: 1px auto;
          padding: 3px 0;
        }
      }
    }
  }
  .monitor-chars {
    background: $o_chars_back;
    padding: 2px 0;
    border-radius: 5px;
  }
  .monitor-zwykle {
    color: $o_zwykle;
  }
  .monitor-uwierzytelnione {
    color: $o_uwierzytelnione;
  }
  .order-monitor-lang {
    font-size: 10px;
    font-weight:100;
    margin-left:20px;
    span {
      opacity:.5;
    }
    display: grid;
    grid-template-columns: 25px 30px 20px;
    align-items: center;
    text-align: center;
    img {
      width: 17px;
      padding: 0px;
      margin: 4px;
      box-shadow: 0 0 0 1px #ffffff, 0 0 2px rgba(0,0,0,.52);
      border-radius: 4px;
      
    }
  }
}
