$border: 1px solid rgb(230, 230, 230);

.spreadsheetView {
  display: grid;
  grid-template-rows: 1fr 67px;
}

.spreadsheet {
  overflow-y: scroll;
  overflow-x: auto;
  //height:100%;
}
.spreadsheetFooter {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0 18px;
  display: flex;
  align-items: center;
  height: 50px;
}

.dataTable {
  border-collapse: collapse;
  border: $border;
  font-size: 14px;
  min-width: 100%;
  td,
  th {
    border-bottom: $border;
    //font-family: $fontStandard2;
  }

  thead {
    font-size: 12.5px;
    position: sticky;
    top: 0px;
    background-color: #e9e9e9;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    height: 60px;
    z-index: 2;
    th {
      font-family: $fontFancy2;
      font-weight: normal;
      border-right: 1px solid lightgrey;
    }
  }

  tbody {
    tr {
      /* animation-name: appear;
      animation-duration: 1s;
      animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      animation-fill-mode: both;*/
      //background-color: rgba(255, 255, 255, 0.6);
      background-color: rgba(246, 246, 246, 0.7);
      &.acceptedRow {
        background: linear-gradient(
          90deg,
          #6ef79e61 0%,
          rgba(white, 0.4) 20%,
          rgba(white, 0.4) 90%,
          #6ef79e61 100%
        );
      }
      transition: 0.23s ease-in-out transform;
      transition: 0.11s ease-in-out box-shadow;
      animation-name: cropUp;
      animation-duration: 0.25s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;
      @for $i from 0 through 42 {
        &:nth-child(#{$i}) {
          animation-delay: #{$i * 0.02}s;
        }
      }
      &:hover .target-language {
        text-shadow: 0px 0px 1px black;
      }
      .target-language {
        transition: 0.15s ease-in-out all;
      }
    }

    .rowGroupInfo {
      font-family: $fontFancy2;
      font-size: 14px;
      font-weight: bold;
      padding-top: 18px;
      padding-left: 8px;
      padding-right: 8px;
      text-align: left;
      //border-bottom: 1px dashed #9b9b9b;
      div {
        display: flex;
        justify-content: space-between;
      }
      //background:linear-gradient(30deg,rgba(200, 255, 0, 0.226), transparent);
      .sumAmount {
        color: rgb(78, 78, 78);
        font-weight: normal;
        font-size: 110%;
      }
    }

    tr:nth-child(odd) td {
      background: #f1f1f1a6;
    }
    tr:hover {
      //background-color: rgba(255, 255, 255, 1);
      transform: translateY(0px);
      box-shadow: 1px 0px 13px rgba(0, 0, 0, 0.17) inset;
      //border-right: 3.2px dashed #2ba75e8f;
      z-index: 2;
      td {
        background: white;
      }
      .registry_number .dataGroup span {
        //transform: scale(1.15);
        color: black;
      }
      .addCopyButton {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    td {
      padding: 7px 1px;
      height: 23px;
      text-align: center;
      transition: 0.26s ease-in-out background;

      position: relative;

      &:nth-child(2n + 3) {
        /* background: #e7e7e7; */
        border-left: 1px dashed rgba(0, 0, 0, 0.05);
        border-right: 1px dashed rgba(0, 0, 0, 0.05);
      }

      &.order_number {
        a.googleDriveLink {
          opacity: 0;
          position: absolute;
          transition: 0.4s cubic-bezier(0.79, 0, 0.94, 0) all;

          img {
            width: 16px;
            height: 16px;
          }

          transform: translateX(-50%);
          bottom: 11%;
        }
        &:hover a.googleDriveLink {
          opacity: 1;
          left: 50%;
        }
      }
      &.registry_number {
        font-size: 150%;
        width: 80px;

        .dataGroup span {
          cursor: pointer;
          transition: 0.2s ease-in-out all;
          font-family: $fontStandard;
          //font-family: monospace;
          //width: 50px;
          //height: 50px;
          padding-left: 10px;
          padding-right: 5px;

          display: flex;
          justify-content: center;
          align-items: center;
          //background: #9ed89e;
          //text-shadow: 1.5px 1.5px 0px white, 5px 5px 2px rgba(0, 0, 0, 0.2);
          margin-left: -2px;
          //margin-bottom: 4px;
          color: #2f7820;
          //border-radius: 0 50% 50% 0;
        }
        font-weight: bold;
      }

      .subitem {
        padding: 4px;
      }

      .addCopyButton {
        font-size: 12px;
        display: none;
        position: absolute;
        bottom: 5%;
        left: 50%;

        transform: translateX(-50%);
        padding: 1vh 12px;
        //background:white;
        //box-shadow:0 0 2px rgba(0,0,0,.3);
        transition: 0.3s ease-in-out all;
        opacity: 0.5;
        border-radius: 50%;
        &:hover {
          // box-shadow:0 0 2px rgba(0,0,0,.83);
          background: lighten($semiActiveColor, 15%);
          opacity: 0.7;
        }
        img {
          width: 22px;
          transition: 0.5s ease-in-out all;
        }

        cursor: pointer;
      }

      .missing_data {
        font-style: italic;
        color: rgb(173, 17, 17);
      }

      .dataGroup {
        span.unedited {
          color: #a3a3a3;
        }
        .unit {
          margin-left: 4px;
          color: grey;
          font-size: 80%;
        }
      }
    }

    td:nth-child(2n + 1) {
      //box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.035) inset;
    }

    td.rowOptions {
      div {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      min-width: 22px;
      overflow: hidden;
      user-select: none;
      -webkit-user-select: none;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        width: 30px;
        font-size: 20px;

        cursor: pointer;
        font-weight: bold;
        color: grey;
        opacity: 0.85;
        transition: 0.2s ease-in-out all;
        &:hover {
          color: red;
          opacity: 1;
          transform: scale(1.1);
        }
        img {
          height: 20px;
        }
      }
    }
  }

  td.selectRow,
  th.selectRow {
    width: 32px;
    input {
      transform: scale(1.35);
    }
  }
}

.inputGroup {
  position: relative;

  span.unit {
    width: 30px;
    position: absolute;
    right: 3px;
    transform: translateY(-50%);
    font-size: 12px;
    font-style: italic;
    color: rgb(148, 148, 148);
    top: 50%;
  }
  button {
    min-height: 15px;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    width: 102px;
    margin: 7px;
    border-radius: 5px;
    display: inline-block;
  }
}
.message {
  font-family: $fontFancy2;
  padding: 32px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 50px auto;
  /* background: #f1ecad; */
  opacity: 0.65;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1) inset;
  color: #6a6a6a;
  border-radius: 35px;
}

.spreadsheet.history {
  td {
    font-size: 12.5px;
    div,
    span {
      padding: 0px;
    }
  }
  .selectRow {
    display: none;
  }
  td:not(.same):not(.selectRow) {
    border-bottom: 4px solid rgba(126, 230, 178, 0.84);
    background: white;
    padding: 2px;
    filter: drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.1));
  }
  td.same {
    opacity: 0.2;
  }
}

// MODULE TO SHOW DUPLICATES AS IN PRINT
/*
.swornRegistry .spreadsheet:not(.history) {
  td.date,
  td.customer, td.order_number {
    &.same {
      .dataGroup {
        display: none;
        * {
          display: none;
        }
        @media print {
          display: none;
        }
      }
      &::before {
        content: "— | | —";
        font-size: 9px;
        opacity: 0.6;
        font-style: italic;
        color: black;
      }
    }
  }
  tr:nth-child(6n + 6) {
    td.date,
    td.customer, td.order_number {
      &.same {
        .dataGroup {
          display: block;
          @media print {
            display: block;
          }
        }
        &::before {
          content: "";
        }
      }
    }
  }
}
*/

//CONNECTED SAME ORDER
.swornRegistry .spreadsheet:not(.history) {
  td.order_number,
  td.customer {
    &.same {
      position: relative;

      .dataGroup {
      }
      &::before {
        content: "";
        position: absolute;

        border-left: 4px dotted rgba(grey, 0.33);
        border-right: 4px dotted rgba(grey, 0.33);
        width: 4px;
        left: calc(50% - 5px);

        height: 20px;
        top: -10px;
      }
    }
  }
  tr:nth-child(6n + 6) {
    td.order_number,
    td.customer {
      &.same {
        .dataGroup {
          display: block;
          @media print {
            display: block;
          }
        }
        &::before {
          content: "";
        }
      }
    }
  }
}

.spreadsheetWrapper {
  display: grid;
  grid-template-rows: 70px calc(97vh - 70px);
}

.spreadsheetView {
  .printPages {
    position: absolute;
    top: 0cm;
    padding-bottom: 0.35cm;
    left: 0.5cm;
    font-family: Calibri !important;
    font-size: 11pt;
    //top: 18.5cm;
    @media screen {
      display: none;
    }
  }
}

.spreadsheet-form {
  padding: 0px;
  margin: -3px -7px -5px;

  .formItem {
    /*padding: 0px 9px;
    display: grid;
    grid-template-columns: 1fr 1.65fr;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid lightgrey;
    margin: 5px 0;*/
    font-family: $fontStandard;
    padding: 0 6px 0 12px;
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    justify-content: space-between;
    align-items: center;
    margin: -1px 0;
    font-family: "Roboto", sans-serif;
    border: 1px solid lightgrey;
    background: rgba(0, 0, 0, 0.03);
    &:nth-child(2n + 1) {
      background: linear-gradient(
        20deg,
        rgba(0, 0, 0, 0.07),
        rgba(0, 0, 0, 0.03)
      );
    }

    .customFieldSwitch {
      background: #d3d3d3;
      border-radius: 4px;
      float: right;
      margin-right: 5px;
      padding: 5px 8px;
      font-size: 11px;
      //display: inline-block;
      user-select: none;
      text-align: center;
      &.disabled {
        background: rgba(200, 200, 200, 0.2);
        color: rgba(100, 100, 100, 0.7);
      }
      &.enabled {
        background: greenyellow;
      }
      cursor: pointer;
      &:hover {
        background: rgb(152, 218, 152);
      }
    }

    &.filled {
      select,
      input,
      textarea {
        border-left: 7px solid rgba(104, 222, 26, 0.58);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
      }
    }

    &.empty {
      * {
        color: rgb(67, 67, 67);
      }
      select,
      input,
      textarea {
        background: linear-gradient(
          -160deg,
          rgb(255, 255, 255),
          rgb(234, 241, 241)
        );
      }
      select:required,
      input:required,
      textarea:required {
        border-left: 7px solid rgba(252, 130, 16, 0.58);
      }
    }
    * {
      font-family: inherit;
    } /*
    &.columns-2 {
      grid-template-columns: 1fr 1fr;
      .column {
        grid-template-columns: 1fr 2fr;
      }
    }*/
    &:hover {
      box-shadow: -7px 0px 9px -8px rgba(0, 0, 0, 0.5);
    }
    label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: $fontFancy;
    }
    .inputGroup label {
      display: inline;
    }
    select,
    input,
    textarea {
      box-sizing: border-box;
      &::placeholder {
        font-size: 13px;
      }
      width: 100%;
      //height: 100%;
      padding: 1.1vh 7px;
      font-size: 14px;
      border: 1px solid lightgrey;
      border-radius: 3px;
      // background: rgba(255, 255, 255, 0.3);
      @media screen and (max-width: 1400px) {
        padding: 0.85vh 7px;
      }
    }
    input[type="checkbox"] {
      transform: scale(1.3);
      margin: 9px;
    }
    span.unit {
      right: 17px;
    }
    input[name="amount"] {
      position: relative;
      &::before {
        content: "zł";
        position: absolute;
        z-index: 2;
        left: 94%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .disabledField {
      font-family: $fontFancy2;
      font-size: 18px;
      padding: 5px;
    }
  }
  button {
    min-height: 50px;
    //margin-top: 12px;
    border-radius: 0;
    border: 2px ridge white;
    border-style: outset;
    &:hover {
      filter: contrast(1.1) brightness(1.15) saturate(1);
    }
  }
  .messageField {
    font-family: fangsong;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 10px;
  }
}

.swornRegistry {
  .pageNavButton {
    padding: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    font-size: 18px;
    border-radius: 50%;
    background: #e3e3e3;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.25);
    margin: 0 2px;
    &:hover {
      color: unset;
      box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.65);
    }
  }

  .reg_info {
    display: flex;
    .reg_info_button {
      height: 32px;
      border-radius: 5px;
    }
    .reg_info_text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      margin: 3px;
      width: 130px;
      height: 30px;
    }
  }
  .rowOptions div {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 80%;
  }
  .disabled {
    opacity: 0.15;
  }
  .numInfo {
    //display: flex;

    margin-left: 10px;
    /*justify-content: center;
    align-items: center;*/
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border-radius: 8px;

    div {
      width: 92px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
      padding: 4px 2px;
      background: rgb(231, 231, 231);
      padding-right: 7px;
    }
    .numCaption {
      font-size: 9px;
      margin-left: 6px;
      margin-right: 6px;
      opacity: 0.4;
    }
  }

  .search_label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0 10px;
  }
  .register_page_shown {
    display: flex;
    align-items: center;
    width: 160px !important;
    background: whitesmoke;
    border-radius: 25px;
    box-shadow: 0 0 5px inset rgba(0, 0, 0, 0.4);
    justify-content: center;
    font-size: 15px;
    height: 60%;
  }
  .spreadsheetView {
    grid-template-rows: 1fr;
  }

  .spreadsheet {
    .dataTable {
      margin-bottom: 50px;
    }
    .dataTable thead tr.littleHeaderInfo th {
      border-top: 2px solid white;
      border-left: 2px solid white;
      border-right: 2px solid white;
      height: 0.2cm !important;
      min-height: unset;
      padding: 0px;
      div {
        font-size: 5pt !important;
        //padding-left: 18.76cm !important;
        padding-left: 9.7cm !important;
        text-align: left;
      }
    }
    tr td {
      height: calc(max(13vh, 100px));

      &.customer {
        width: 260px;
      }

      &.source_language {
        width: 69px;
      }

      &.drawn_up_by {
        width: 170px;
      }

      &.user_name {
        width: 100px;
      }

      &.document_return,
      &.amount_received {
        width: 90px;
      }

      &.document_return {
        .dateTypeSign {
          display: none;
          position: absolute;
        }
        //&:hover {
        .dateTypeSign {
          border-radius: 7px;
          padding: 1px 7px;
          top: 130%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 9px;
          color: grey;
        }
        //}
        .dataGroup span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
        }
      }
      .document_name {
        font-family: monospace;
        font-weight: bold;
        letter-spacing: -0.25px;
        @media print {
          font-weight: normal;
        }
      }

      @media screen and (max-width: 1300px) {
        * {
          padding: 1px;
        }

        height: 100px;
        * {
          font-size: 12px;
        }
        padding: 0;
        &.document_data {
          * {
            font-size: 12px;
            width: 180px;
          }
        }
        &.registry_number {
          * {
            font-size: 17px;
          }
        }

        &.customer {
          width: 200px;
        }
      }

      &.user_name {
        font-size: 12px;
        span.date_added {
          color: #777777;
          text-shadow: 0.5px 0.5px 2px white;
        }
      }
    }
    td,
    th {
      &.selectRow {
        display: none;
      }
      sup {
        font-size: 5pt !important;
        margin-bottom: 4px;
      }
    }
  }
}

.modal {
  .dataTable td.registry_number .dataGroup span {
    background: none;
  }
}

.spreadsheet-form.newCopy {
  select,
  input,
  textarea {
    &[disabled] {
      //padding:4px 4px;
      border-left: none !important;
      border: none;
      appearance: none;
      color: black;
      background: none !important;
      box-shadow: none !important;
    }
  }
  .customFieldSwitch {
    display: none;
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    display: none;
  }
}
