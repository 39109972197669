.now-wrap {
  $back: rgb(41, 90, 105);
  $borderline: 3px solid $back;
  * {
    font-family: $fontFancy2;
  }
  &.partScreen {
    max-height:360px;
    overflow-y:scroll;
  }
  .switching {
    display: flex;
    justify-content: center;
    button {
      max-width: 100px;
      height: 40px;
    }
  }
  .data-view {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  .person {
    padding: 5px 5px;
    .person-name {
      color: white;
      font-size: 14px;
      padding-left: 5px;
      background: $back;
      padding: 5px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      margin-bottom: -3px;
      top:0;
      position:sticky;
      z-index:2;
      box-shadow:4px 2px 12px white;
      &.went {
        background:grey;
      }
    }
    .person-work-start {
      position: relative;
      font-size: 11px;
      font-weight: normal;
    }
    .person-items {
     
      padding-top: 18px;
      padding-left: 20px;
      border-left: $borderline;
      font-size: 12px;
      .person-item {
        padding: 5px;
        position: relative;
        //background: whitesmoke;
        border-radius: 5px;
        margin: 2px;
        box-shadow: -4px 4px 0 -3px rgba($back,.1);
        //box-shadow: 0 0 1px rgba(0, 0, 0, 0.62);

        .started {
          font-size: 15px;
          padding: 2px 5px;
          margin-left: -5px;
          border-radius: 5px;
          box-shadow: 0 0 1px 1px $back;
          margin-bottom: 6px;
          position: relative;
          &::before {
            content: "";
            width: 22px;
            border-top: $borderline;
            height: 1px;
            position: absolute;
            right: calc(100%);
            top: 45%;
          }
        }
        .order {
          font-size: 16px;
          font-weight:100;
        }
        .customer {
          font-weight:600;
          font-size:14px;
          font-family: $fontFancy;
        }
        .count {
            margin-top:4px;
            color:rgb(70,70,70);
        }
        &:nth-child(2n + 1) {
          //background: whitesmoke;
          border-radius: 5px;
        }
      }
    }
  }
}
