.customer-service {
  display: grid;
  grid-template-rows: 160px 1fr;
  height: 100vh;
  z-index:0;
  .loading {
    font-size: 30px;
  }
  .top-bar {
    nav {
      margin-top: 9px;
      width: 100%;

      > ul {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          padding: 11px 4px;
          font-size: 16px;
          font-weight: bold;
          display: block;
          font-family: $fontFancy;
          color: black;
          position: relative;
          text-align: center;
          cursor: pointer;
          &:hover {
            background: $activeColor;
            color: white;
          }
          .cs-sub-menu {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            text-align: center;
            width: 100%;
            border-bottom: 1px solid rgb(128, 128, 128);
            border-left: 1px solid rgb(128, 128, 128);
            border-right: 1px solid rgb(128, 128, 128);
            background: whitesmoke;
            margin: 0;
            padding: 0;
            animation-duration: 0.18s;
            animation-delay: 0.18s;
            animation-name: appear;
            animation-fill-mode: both;
            li {
              margin: 0;
              padding: 5px 11px;
              text-align: left;
              font-family: $fontStandard;
              font-weight: normal;
              border-bottom: 1px solid lightgrey;
            }
          }
          &:hover .cs-sub-menu {
            display: block;
            opacity: 1;
          }
        }
      }
    }
    form {
      font-size: 11px;
      display: flex;
      padding: 10px;
      justify-content: space-between;
      div {
        display: block;
        padding: 5px;
        label {
          padding: 3px;
          width: 100%;
          display: block;
        }
      }
    }
  }
  .cs-interface {
    display: grid;
    grid-template-columns: 1fr 4fr;
    .cs-sidebar {
      background: rgba(245, 245, 245, 0.93);
      .cs-sidebar-buttons {
        display: block;
        overflow: auto;

        max-height: 70vh;
        button {
          display: block;
          padding: 22px 10px;
          font-family: $fontStandard;
          text-transform: none;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
          margin: 4px 0;
        }
      }
    }
    .cs-edit-order {
      position:relative;
      padding:20px 55px;
      .cs-edit-basic {
        max-width:700px;
        border-radius:5px;
        overflow:hidden;
        box-shadow:5px 5px 0px rgba(0,0,0,.03);
        border: 1px solid grey;
        
        h1, h2 {
          margin:0;
          padding:5px 9px;
          display:flex;
          justify-content: space-between;
          .cs-edit-button {
            display:flex;
            height:100%;
            margin:0;
            height:24px;
            padding:0;
            align-items: center;
            justify-content: center;
            max-width:200px;
          }
        }
        h1 {
          font-size:32px;
          background:whitesmoke;
        }
        h2 {
          font-size:18px;
          background:lightgreen;
        }
      }
    }
    .cs-main {
      overflow: auto;
      max-height: calc(100vh - 120px);
    }
    table.cs-main-table {
      //max-width:900px;
      width: 99%;
      margin: 0 12px 12px 12px;
      border-collapse: collapse;
      tr {
        background: rgba(245, 245, 245, 0.63);
        &:nth-child(2n+2) {
          background: rgba(207, 243, 207, 0.418);
        }
        &.cancelled {
          color:#b1b1b1;
        }
      }
      thead {
        top: 0;
        position: sticky;
        //background: $activeColor;
        background: rgb(245, 245, 245);
        z-index: 1;
        box-shadow: 0 1px 0 -1px lightgrey, 0 1px 5px -1px rgba(0, 0, 0, 0.1);
      }
      td {
        border: 1px solid rgb(214, 214, 214);
        font-size: 15px;
      }
      td,
      th {
        text-align: center;
        font-family: $fontStandard;
        padding: 4px 1px;
        &.customers_name {
          max-width:500px;
          text-align: left;
          padding: 12px 12px;
          div:hover {
            text-decoration: underline;
            cursor:pointer;
          }
          .sp {
            margin-top:6px;
            margin-bottom:2px;
            &.paid {
              color:green;
              &::before {
                background-color:green;
              }
            }
            
          }
          .sp, .so {
            color:grey;
            font-size:11px;
            &::before {
            padding:1px 2px;
            display:inline-block;
            width:15px;
            margin-right:3px;
            left:0;
            background-color:rgb(121, 121, 121);
            color:white;
            font-size:10px;
            border-radius:5px;
            text-align:center;
          }
          }
          .sp::before {
            content: "SP";
          }
          .so::before {
            content: "SO";
          }
        }
        &.number_order {
          font-weight: bold;
          font-size: 19px;
          width: 75px;
        }
        &.dateTime {
          width: 80px;
          &.reception {
            color:rgb(180, 180, 180);
          }
        }
        &.ready, &.past, &.soon, &.waiting {
          font-size:11px;
        }
        &.past {
          background: linear-gradient(20deg, ghostwhite, #c30000, ghostwhite);
          font-weight: bold;
          color: white;
        }
        &.soon {
          background: linear-gradient(20deg, ghostwhite, #efef5c, ghostwhite);
          font-weight: bold;
        }
        &.waiting {
        }

        &.ready {
          background: linear-gradient(20deg, ghostwhite, #28a339, ghostwhite);
        }
        &.language {
          div {
            text-align: center;
            font-size: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              box-shadow: 0 0 1px 1px black, 2px 2px 5px rgba(0, 0, 0, 0.1);
              max-width: 28px;
              opacity: 0.9;
              margin: 4px;
            }
          }
        }
        &.documents {
          max-width:350px;
          .tabDocumentsWrap {
            display: grid;
            grid-template-columns: 18px 1fr 40px;
            grid-gap:9px;
          }
          .tabDocumentsNumber {
            font-size:14px;
            display:flex;
            justify-content: center;
            align-items: center;
            width:15px;
            padding:4px;
            height:15px;
            border-bottom:1px solid rgb(202, 202, 202);
          }
          .tabDocuments {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            span {
              text-align:left;
              padding: 3px;
              margin: 2px;
            }
          }
          .tabPages {
            font-size:10px;
            height:100%;
            display:flex;
            align-items:center;
          }
          
        }
      }
      th {
        font-size: 14px;
      }
      margin-bottom: 60px;
    }
  }
}
