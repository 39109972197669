@media print {

  * {
    // display:none;
    background: none !important;
    box-shadow: none !important;
  }

  .top-bar {
    display: none;
  }
  .no-print {
    display: none;
  }

  select,
  button,
  input {
    display: none;
  }

  .App {
    height: auto;
    overflow: auto;
    max-height: auto;
  }
  .mainEditor,
  .wrap {
    display: block;
    width: 100%;
    height: 100%;
    .infobar {
      display: none !important;
    }
  }

  .listing {
    height: auto;
    max-height: none;

    overflow: auto;
    .listingTable {
      margin: 0.2cm;
      width: 18cm;
      border-collapse: none;
      border: solid rgb(235, 235, 235) !important;
      border-width: 0.4pt 0 0 0.4pt !important;

      td,
      th {
        padding: 1px;
        font-size: 8pt;
        font-weight: normal;
        font-family: "Arial", sans-serif !important;
        border: solid rgb(235, 235, 235) !important;
        border-width: 0 0.4pt 0.4pt 0 !important;
      }
    }
  }

  .spreadsheetWrapper,
  .spreadsheet {
    display: block;
    height: auto;
    max-height: none;
    overflow: hidden;
    //max-width:20cm;
    font-size: 12pt;
  }
  .spreadsheet,
  .orders {
    background: none;
  }

  .spreadsheet tr {
    animation-name:none !important;
  }

  .swornRegistry .spreadsheetWrapper,
  .swornRegistry .spreadsheetWrapper .spreadsheet {
    max-width: 29cm;
    width: 29cm;

    * {
      //font-family: "Arial", sans-serif !important;
      font-weight: normal;
      font-size: 11pt;
      font-style: normal !important;
      color: black;
    }
  }

  .swornRegistry .spreadsheet {
    tr td {
      height: 2.7cm;
      max-height: 2.7cm;
    }
    .dataTable tbody td {
      .subItem {
        padding:1px;
      }
    }
    * {
      color:black !important;
    }
    tr td,
    tr th {
      border: 1px solid black;
      //border-right: 1px dashed rgba(0,0,0,.05);
      span {
        text-shadow:none !important;
        margin-bottom:none;
      }
    }
    tr td,
    .dataTable thead tr {
      * {
        font-family: "Arial" !important;
        font-size: 9pt;
        font-weight: normal;
        color: black;
        font-style: normal;
      }
    }
    .dataTable thead tr th {
      
      height:1.5cm;
      span {
        font-size: 7pt;
        display:flex;
        justify-content: baseline;
        align-items: flex-end;
        height:100%;
        text-align:left;
        padding-left:.1cm;
      }
    }
    .order_number {
      display:none;
    }
    .rowOptions {
      display:none;
    }
  }

  .only-screen {
    display:none !important;
  }
}

@media screen {
  .only-print {
    display:none !important;
  }
}

@page {
    size: A4 landscape;
    margin: 15mm 8mm 8mm 8mm;    
}