html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
* {
  //scroll-behavior:smooth;
}
*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 5px;
  border: 1px solid var(--scrollbarBG);
}

.tiny-scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
    height: 9px;
    
  }
  &::-webkit-scrollbar-thumb {
   
    background:rgba(87, 155, 170, 0.349);
  }
  &::-webkit-scrollbar-track {
    background:#cfd8dc6a;
    border-radius:4px;
  }
}
