.modalWrap.sworn-registry-form {
  .modal .content {
    padding: 0;
  }
}

form.universal-forms {
  background: rgb(214, 214, 214);

  button {
    background: #3a6b6b;
    border-radius: 15px;
    box-sizing: border-box;
    max-width: max-content;
    margin: 10px 5px 12px auto;
    box-shadow: none;
    color: white;
    padding: 15px 26px;
    font-weight: 100;
    font-size: 14px;
    border: none;
    &:hover {
      color: white;
    }
  }
  button.submit {
    margin: 0;
  }
  .uf-field-sets {
    display: flex;
    background: rgb(238, 238, 238);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.076);
  }
  width: 100%;
  .uf-field-set {
    margin: 0;
    &:first-child {
      box-shadow: 15.5px 0 25px -19px rgba(0, 0, 0, 0.11); //0.5px 0 rgba(0, 0, 0, 0.1);
    }
    &:last-child {
      padding-bottom: 12px;
    }
    padding: 0;
    border: none;
    width: 100%;
    //padding: 8px 25px 15px 10px;

    &:last-child {
      // padding: 8px 10px 15px 10px;
    }
    &:not(:first-child) {
      //  padding-left: 25px;
    }
    .uf-field-set {
      //sub
      display: flex;
      justify-content: space-between;
      //display: grid;
      //grid-template-columns: 1fr 1fr;
      margin: 0;
      padding: 5px 0px;
      .uf-field {
        &:not(:last-child) {
          padding-right: 7px;
        }
        margin-top: 0;
      }
    }
  }
  .uf-field {
    padding: 5px 15px;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    .field-buttons {
      * {
        animation-name: appear;
        animation-timing-function: cubic-bezier(0.19, 2, 0.22, 1);
        animation-duration: 0.64s;
        animation-fill-mode: both;
      }
      position: absolute;
      right: 15px;
      top: 8px;
      z-index: 2;
    }
    .field-button:empty {
      background: transparent;
      padding: 0;
    }
    .field-button:not(:empty) {
      font-size: 11px;
      background: #385252;
      color: white;
      width: 24px;
      height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      cursor: pointer;
      user-select: none;
      filter: opacity(0.2);
      &:hover {
        background: #567373;
      }
      img {
        width: 12px;
        filter: invert(1);
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .field-button:not(:empty) {
        filter: opacity(1);
      }
    }
    &:not(:first-child) {
      //margin-top: 7px;
    }

    label {
      font-family: $fontFancy;
      font-size: 13px;
      display: block;
      padding: 3px 1px;
    }
    input,
    select {
      height: 28px;
      padding: 3px 6px;
      width: 100%;
      font-size: 14px;
      border: none;
      outline: none;
      box-shadow: 0 0 0px 0.5px rgba(0, 0, 0, 0.3);
      background: white;
      border-radius: 3px; //16px;
      box-sizing: border-box;
      font-family: $fontFancy2;
      border-bottom: 0.5px solid rgb(63, 208, 152);
      //box-shadow: 0 5px 5px -8px rgb(63, 208, 152) inset;
      box-shadow: 0 2px 0px 0px rgb(101 212 152);
      &.empty {
        border-bottom: 0.5px dashed rgb(139, 139, 139);
        box-shadow: none;
        background: rgba(255, 255, 255, 0.211);
      }

      
    }
    input:read-only {
      background: transparent;
      color: black;
      opacity: 1;
    }

    .search-box {
      font-size: 15px;
      width: 100%;
      button {
        img {
          filter: invert(1);
        }
        margin: 0;
        padding: 3px 10px;
      }
      button.no-selection {
        font-size: 12px;
        padding: 3px 7px;
      }
      input {
        height: 28px;
        padding: 2px 15px;
        margin: 0;
        font-family: $fontFancy2;
      }

      .input-divide {
        grid-template-columns: 1fr 56px;
      }

      .search-input.filled-input {
        height: 28px;
        background: white;
        border-radius: 3px; //16px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        padding: 0;
        * {
          font-family: $fontStandard;
        }
        &:hover {
          background: whitesmoke;
        }
      }
      .filled-input-activate:hover {
        background: transparent;
      }
      .search-box-results {
        max-height: 250px;
        min-width: 700px;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
        border-radius: 3px; //16px;
        z-index: 4;
        .order-item * {
          font-family: $fontFancy2;
        }
        font-size: 13px;
      }
      .filled-input-box button.erase-button {
        background: none;
        img {
          filter: invert(0);
        }
      }
    }
    .order-species .order-item.short {
      grid-template-columns: 80px 1fr 30px;
      padding: 0;
      padding: 0;
      box-shadow: none;
      background: none;
      margin-right: 2px;
    }
  }
  .uf-field-set .uf-field-set {
    padding: 5px 2px 5px 15px;
    box-sizing: border-box;
    &:last-child {
      padding-bottom: 15px;
    }
  }
  .uf-field-set .uf-field-set .uf-field {
    padding: 0;
    input,
    select {
      max-width: 92%;
    }
  }
}
.search-box-document-item {
  padding: 2px 4px;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr 0.7fr 25px max-content;
  > div {
    padding: 4px;
  }
  .number {
    padding: 0;
    width: 18px;
    height: 18px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background: rgb(63, 107, 112);
    color: white;
  }
  .minor:not(:empty) {
    font-size: 10px;
    background: whitesmoke;
    padding: 2px 4px;
    border-radius: 5px;
    color: rgb(97, 97, 97);
  }
}
