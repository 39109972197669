.table-details {
  max-width: 100vw;
  margin: 0px auto 20px;
  background: whitesmoke;
  padding: 2px;
  column-count: 1;
  @media screen and (min-width: 800px) {
    column-count: 2;
  }
  @media screen and (min-width: 1300px) {
    column-count: 3;
  }
  @media screen and (min-width: 1600px) {
    column-count: 4;
  }

  box-sizing: border-box;
  column-gap: 15px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  .details-browse {
    button {
      background: none;
      color: white;
      font-size: 16px;
      padding: 0;
    }
  }
  .details-heading {
    position: sticky;
    font-family: $fontFancy;
    column-span: all;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 16px;
    top: 0;
    //box-shadow: 0 0 5px rgba(0,0,0,.3);
    //border-radius: 8px;
    background: #8ea39f;
    color: white;
    margin-bottom: 10px;
    button {
      border-radius: 10px;
      width: auto;
      padding: 5px 8px;
    }
  }
  .details-prop-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    //box-shadow: 0 0 2px rgba(0,0,0,.3);
    padding: 2px;

    //background: white;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: space-between;

    .details-prop-name {
      font-size: 12px;
      color: grey;
      font-family: $fontFancy;
      text-align: right;
      padding: 6px 22px;
      min-height: 20px;
      &:empty {
        visibility: hidden;
      }
    }
    .details-prop-value {
      font-size: 13px;
      font-family: $fontFancy2;
      font-weight: 100;
      box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
      padding: 6px;
      min-height: 20px;
      word-wrap: break-word;
      max-width: 200px;
      @media screen and (max-width: 1300px) {
        max-width: 300px;
      }
      &:empty {
        visibility: hidden;
      }
    }
  }
}

@keyframes appear-fancy {
  0% {
    scale: 1.5;
    rotate: y -55deg;
    opacity: 0;
  }
  100% {
    scale: 1;
    rotate: y 0deg;
    opacity: 1;
  }
}
@keyframes appear-fancy-slight {
  0% {
    scale: 1.03;
    rotate: y -8deg;
    opacity: 0;
  }
  100% {
    scale: 1;
    rotate: y 0deg;
    opacity: 1;
  }
}

@keyframes extend-right {
  0% {
    text-overflow: clip;
    white-space: pre;
    overflow: hidden;
    max-width: 0%;
  }
  100% {
    text-overflow: clip;
    white-space: pre;
    overflow: hidden;
    max-width: 100%;
  }
}
.universal-table-controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 2px;

  border-top: 1px solid rgba(145, 128, 34, 0.156);
  background: #fcfcfc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  > .table-name {
    position: relative;
    font-size: 17px;
    margin-top: -5px;
    //font-weight: 100;
    text-transform: uppercase;
    border-radius: 16px;
    background: rgb(195, 241, 104);
    justify-content: flex-start;
    * {
      font-family: $fontFancy2;
    }
    img.main-table-icon {
      width: 29px;
      margin-right: 15px;
      margin-left: 5px;
      animation-name: appear-fancy;
      animation-duration: 0.35s;
      animation-timing-function: ease-in;
      animation-fill-mode: both;
    }

    &:has(img) {
      .main-table-name {
        animation-name: extend-right;
        animation-duration: 0.95s;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
      }
    }
    padding: 0 16px;
    button.new {
      display: flex;
      align-items: center;
      margin-left: 23px;
      font-size: 16px;
      background: white;
      padding: 0 10px;
      .icon {
        font-weight: 700;
      }
      &:hover {
        /*background: green;
        color: white;*/
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.13);
      }
    }
    .table-image {
      display: inline-block;
      width: 26px;
      padding: 12px;
      font-size: 18px;
      border-radius: 7px;
      background: white;
      margin-right: 10px;

      margin-top: -7px;
      margin-left: -22px;
      color: black;
      display: flex;
      z-index: 3;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px -1px rgb(53, 68, 74);
      cursor: pointer;
      //opacity: 0.9;
      transition: 0.1s ease-in-out all;
      &:hover {
        background: lightblue;
      }
    }
    .menu-roll-down {
      position: absolute;
      top: 95%;
      min-width: 180px;
      left: -5px;
      z-index: 5;
      background: white; //#b5dce8;
      //padding: 12px;
      box-shadow: 3px 3px 5px -3px rgba(0, 0, 0, 0.3);
      border-radius: 0 12px 12px 12px;
      overflow: hidden;

      div {
        img {
          width: 21px;
          margin-right: 8px;
        }
        &:has(img) {
          display: flex;
        }
        font-size: 16px;
        padding: 7px 11px;
        cursor: pointer;
        color: black;
        &:hover {
          background: rgb(100, 126, 136);
          color: white;
          img {
            filter: invert(1);
          }
        }
      }
    }
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filters {
    font-size: 13px;
    max-width: 100%;
    margin: 0;
    margin-top: 5px;
    justify-content: space-between;
    width: 100%;
    .number-of-results {
      background: #e3eecf;
      //  box-shadow:0 0 3px #cee2a8 inset;
      padding: 2px 8px;
      height: 15px;
      font-size: 10px;
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      animation-name: extend-right;
      animation-duration: 1.83s;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
      animation-fill-mode: both;
      //   border-radius: 50%;
    }

    .selected-highlight:not(:empty) {
      font-weight: 700;
      font-family: $fontFancy2;
      font-size: 16px;
      display: flex;
      align-items: center;
      width: max-content;
      padding: 5px 12px;
      box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
      //background: #73868c;
      border-radius: 3px;
      border: 0.5px rgb(225, 225, 225) solid;
      // color: white;
      text-align: center;
      margin-right: 11px;
      .little-flag {
        width: 20px;
        min-height: 15px;
        margin: 0;
        margin-left: 10px;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
      }
    }
    /*
    .filter-single:not(.active) {
      opacity: 0.73;
    }

    &:hover .filter-single {
      opacity: 1;
    }*/
    .filter-collection {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;
      padding: 2px;
      .filter-single {
        /*        display: flex;
        align-items: center;
        justify-content: space-between;*/
        padding: 1px 4px;
        margin: 0 2px;
        position: relative;
        //background:rgb(253, 253, 253);

        transition: 0.1s ease-in-out all;
        transition-delay: 0.2s;

        .filter-name {
          color: rgb(56, 56, 56);
          font-weight: 100;
          font-size: 11px;
          font-family: $fontFancy;
          padding: 1px 1px;
          //margin-right: 7px;
        }
        .filter-value {
          display: flex;
          align-items: center;
          input,
          select {
            box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.13),
              0 0 2px rgba(0, 0, 0, 0.2);
            padding: 1px 5px;
            font-size: 12px;
            margin: 0;
            width: 100px;
            height: 20px;
            border: unset;
            font-weight: 400;
            background: #e9eff3; //rgb(230, 230, 230);
            border-radius: 3px;
            &:focus {
              outline: 1px solid rgb(207, 207, 207);
            }

            &[value=""] {
              background: white;
            }
          }
        }
        &.active {
          opacity: 1;
          background: white;
          scale: 1;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          .filter-name {
            color: black;
          }
          .filter-value input {
            background: #d7f6fa;
          }
        }
        &.pick-translator {
          background: whitesmoke;
          border-radius: 4px;
          display: flex;
          align-items: center;
          .filter-name {
            font-weight: bold;
            text-transform: uppercase;
          }
          padding: 3px 0px 3px 12px;

          box-shadow: 0 -5px 17px rgba(0, 0, 0, 0.1) inset;
          margin: 0 10px;

          input {
            background-color: unset;
          }
        }
        .translator-picker {
          padding: 0px 0px;
          font-size: 14px;
          box-shadow: 0 0 11px rgba(0, 0, 0, 0.052);
        }

        .filter-single-clear {
          display: none;
          position: absolute;
          cursor: pointer;
          font-size: 8px;
          background: white;
          opacity: 0;
          padding: 2px;
          text-transform: uppercase;
          right: 0px;
          top: -7px;
          border-radius: 5px;
          transition: 0.5s ease-in-out opacity;
          img {
            width: 13px;
          }
        }
        &:hover {
          //.filter-single-clear:is(:has(select:not(:empty))) {
          .filter-single-clear {
            display: block;
            opacity: 0.7;
          }
        }
      }
      .filter-single.big {
        .filter-value {
          font-size: 120%;
        }
        .filter-name {
          color: grey;
        }
      }
    }
  }

  .pagination > div {
    font-family: $fontFancy;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 100;
    width: 130px;
  }
  .pagination .tiny-input {
    padding: 0;
    background: none;
    margin: 0;
    width: 50px;
    font-family: $fontFancy;
    font-size: 13px;
  }
  button {
    padding: 3px 9px;
    width: auto;
    height: 25px;
    margin: 1px;
    border-radius: 5px;
    //margin: 0 20px;
    font-size: 12px;
    //background: none;
    color: rgb(37, 37, 37);
    &:hover {
      background: rgb(228, 228, 228);
      color: rgb(26, 83, 148);
    }
  }
  .sumField:not(:empty) {
    background: #c3f168;
    padding: 0 26px;
    height: 28px;
    font-size: 20px;
    border-radius: 28px;
  }
}
.universal-table {
  border-collapse: collapse;
  * {
    font-family: $fontStandard;
  }
  font-size: 11px;
  width: 100%;

  thead {
    padding: 0;
    position: sticky;
    top: -0.25px;
    z-index: 2;
    background: rgb(237, 237, 237);

    box-shadow: 0 0 1px rgba(0, 0, 0, 0.64);
    th {
      font-family: $fontFancy;
      cursor: pointer;
      text-transform: lowercase;
      padding: 5px;
      &:hover {
        color: rgb(61, 57, 57);
      }
      &.active {
        color: #178cb0;
      }
      text-align: center;
      &:nth-child(2n + 1) {
        background: rgba(255, 255, 255, 0.25);
      }
    }

    .multi-options {
      padding: 0px;
      background: #c0dcef !important;
      text-transform: unset;
      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 6px 14px;
          width: max-content;
          margin: 0;
          list-style: none;
          &:hover {
            background: white;
          }
        }
      }
    }
  }
  tbody {
  /*  animation-name: appear-fancy-slight;
    animation-duration: 0.03s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: both;*/

    .group-heading {
      background: #ececec;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) inset;
      z-index: 3;

      td {
        font-size: 16px;
        padding: 22px 27px 12px;
        text-align: left;
        background: #ddf6fd;
        background: none;
        box-shadow: 0 -7px 11px -12px rgba(0, 0, 0, 0.4) inset;
      }
    }
    tr {
      background: rgba(white, 0.88);
      &:hover {
        // background: rgba(rgb(208, 232, 240), 0.72);
      }
    }
    tr:nth-child(2n + 1) {
      background: rgba(rgb(248, 248, 248), 0.88);
      &:hover {
        //background: rgba(rgb(88, 179, 207), 0.2);
        //  background: rgba(rgb(208, 232, 240), 0.72);
      }
      &.group-heading {
        background: #ececec;
      }
    }
    tr {
      .row-buttons {
        display: flex;
        background: rgba(255, 255, 255, 0.315);
        border-radius: 10px;
        button {
          filter: saturate(0.2) contrast(1.3) brightness(1.1);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px;
          height: 100%;
          font-size: 11px;

          opacity: 0.6;
          max-width: 25px;
          background: unset;
          //background: #c1c1c1;
          border-radius: 50%;
          transition: 0.1s ease-in all;
          //box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.2);
          min-width: 25px;
          img {
            width: 16px;
          }
          &:hover {
            filter: saturate(0.5) contrast(1.3) brightness(1);
            color: black;
            opacity: 1;
            //background:white;
            box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
          }
        }
        > a {
          margin: 0 3px;
        }
      }
    }
    td:nth-child(2n + 2) {
      //background:rgba(black,.01);
    }
  }
  td,
  th {
    //border:.5px solid lightgray;

    padding: 5px 2.5px;
    text-align: left;
    min-width: 50px;
    font-family: $fontFancy2;
    * {
      font-family: $fontFancy2;
    }
    a {
      color: #178cb0;
    }
    &.checkbox {
      width: 17px;
      min-width: 0;
      padding-left: 10px;
    }
    input[type="checkbox"] {
      scale: 1.2;
    }
    .more-info {
      font-size: 8px;
      font-weight: 700;
      font-family: $fontFancy;
      display: inline-flex;
      margin-left: 6px;
      justify-content: center;
      align-items: center;
      width: 12px;
      padding: 2px 3px;
      color: white;
      height: 6px;
      background: #94b3bd;
      border-radius: 50%;
      //box-shadow: 0 0 4px rgba(0,0,0,.3);
    }
    .cell-inner {
      display: flex;
    }
    .notes {
      max-height: 32px;
      font-size: 9px;
      overflow-y: auto;
      padding: 0px;
      line-height: 140%;
      //background: white;
      //border-radius: 2px;
      //  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }
    .big-client-name {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 17px;
      cursor: pointer;
      transition: 0.1s ease-in-out background, 0.2s ease-in-out box-shadow,
        0.33s ease-in-out translate;
      border-radius: 20px;
      &:hover {
        background: linear-gradient(
          30deg,
          rgb(255, 255, 255) 0%,
          rgb(230, 239, 251) 100%
        );
        translate: -1px -1px;

        box-shadow: 0 0 0 3px white, 0 0 6px rgba(0, 0, 0, 0.53);
        * {
          color: rgb(6, 88, 106);
        }
      }
    }
    .document-names {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      font-size: 11px;

      > div {
        background: #c2edfa99;
        //font-family: "Encode Sans SC", sans-serif;
        
        //box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
        padding: 2px 7px;
        margin: 1px;
        height: 14px;
        border-radius: 2px;
        max-width:500px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .customer-type-institution,
    .customer-type-company,
    .customer-type-person {
      padding: 3px;
      border-radius: 5px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
      font-size: 11px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }
    .customer-type-institution {
      background: rgb(233, 236, 178);
    }
    .customer-type-company {
      background: rgb(178, 236, 178);
    }
    .customer-type-person {
      background: rgb(178, 231, 236);
    }
  }

  tr.selected {
    background: rgba(53, 230, 91, 0.263);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  }
  tr.selected:nth-child(2n + 1) {
    background: rgba(53, 230, 91, 0.282);
  }
  th {
    overflow-wrap: anywhere;
    padding: 10px 2px;
  }

  &.big {
    font-size: 14px;
  }

  .loading-table {
    animation-name: appear;
    animation-delay: 0.22s;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    animation-duration: 0.2s;
    font-size: 18px;
    padding: 40px 20px;
    display: flex;
    align-items: center;
    > * {
      padding: 7px;
    }
  }
  .invoice-group:not(:empty) {
    background: #f9f9f9;
    margin-bottom: -11px;
    margin-top: -5px;
    margin-left: -30px;
    padding: 3px 9px;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    img {
      opacity: 0.6;
    }
  }
  .number-fancy {
    //font-family:monospace;

    font-size: 13px;
  }
  
  tr, tr:nth-child(2n+1){
    transition:.07s ease-in-out all;
    &:hover {
    background:rgba(8, 67, 73, 0.005);
    box-shadow:0 0 15px rgba(0,0,0,.039) inset;
    
    }
  }
}

.create-wrapper {
  max-height: 90vh;
  overflow-y: scroll;

  button.return {
    width: 75px;
    height: 45px;
    margin-left: -8px;
    margin-top: -25px;
    background: #83a6b1;
    border-radius: 0 0 40px 0;
    color: white;
    font-size: 32px;
    font-weight: 800;
    position: sticky;
    top: 0;
    z-index: 4;
    font-family: $fontStandard;
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) inset;
    &:hover {
      background: #a5b9be;
    }
  }
}
.create-form {
  .create-info {
    font-family: $fontFancy;
    padding: 10px;
  }
  //column-count: 1;
  display: block;
  max-width: 900px;

  * {
    box-sizing: border-box;
  }
  margin: 0 auto;
  // width:500px;

  .section-group {
    animation-name: appear-fancy-slight;

    animation-fill-mode: both;
    animation-timing-function: ease-in;
    animation-duration: 0.05s;
    display: grid;
    grid-template-columns: 1fr 1fr;
    //align-items: baseline;
    padding-bottom: 50px;
    grid-gap: 15px;
    //background: #f8f8f8;
    //padding: 6px 20px;
    //border-radius: 10px;
    //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.28);
  }
  &.three-columns {
    max-width: 1200px;
    padding: 10px;
    .section-group {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media screen and (max-width: 900px) {
    .section-group,
    &.three-columns .section-group {
      grid-template-columns: 1fr;
      padding: 10px;
      max-width: 100vw;
      .section {
        margin: 0 auto;
        width: 100%;
        max-width: 480px;
      }
    }
  }

  .section-group,
  .button-wrap {
    margin: 5px 0;
  }
  .section {
    //padding: 15px 0;
    background: rgb(247, 247, 247);
    background: #f5f5f5;
    box-shadow: 0px 0px 32px -8px rgba(0, 0, 0, 0.12);
    //box-shadow: 0 0 0 0.5px rgba(0, 0, 0, 0.13);
    border-radius: 3px;

    //overflow: hidden;
    //margin:12px;
    .section-fields {
      // padding: 2px 15px;
      padding: 4px 16px;
      .columns-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: baseline;
        grid-gap: 12px;
      }
    }
    .section-title {
      //background:rgb(255, 255, 255);
      font-family: $fontFancy;
      color: #178cb0;
      font-size: 19px;
      font-weight: 100;
      //background: rgb(251, 251, 251);
      padding: 8px 16px;
      overflow: hidden;
      border-radius: 5px 5px 0 0;
      //box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
      //box-shadow:0px 2px 5px -3px rgba(0,0,0,.24);

      translate: 0px;
    }
  }
  .field-group-heading {
    font-size: 11px;
    padding: 2px 12px;
    margin-bottom: -4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: rgb(233, 233, 233);
    font-family: $fontFancy2;
    text-align: center;
    border-radius: 5px;
    width: max-content;
    margin: 0 auto;
  }
  .field-group {
    display: grid;
    padding: 0;
    position: relative;
    //background:white;
    //border-radius:5px;
    //overflow: hidden;
    margin-bottom: 12px;
    .field-inner {
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;
      border-radius: 0 3px 3px 3px;
      border: 0.5px solid rgb(189, 189, 189);
      select,
      input {
        border: none;
      }
    }
    .field-data-button {
      font-size: 27px;
      border-radius: 50%;
      height: 26px;
      margin-left: 3px;
      width: 26px;
      padding: 0;
      display: flex;
      justify-content: right;
      align-items: center;
      background: none;
      &:hover {
        color: rgb(60, 60, 60);
      }
    }
    .field-info {
      font-size: 11px;
      //padding: 10px;
      display: flex;
      > div {
        padding: 5px 8px;
      }
      //  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
      margin: 3px 0;
      //margin-bottom: 37px;
      // background: lightgrey;
    }
    label {
      font-size: 13.5px;
      font-weight: 100;
      display: flex;
      justify-content: space-between;
      //width: max-content;
      width: 100%;
      transition: 0.3s ease-in-out all;
      border-radius: 3px 3px 0 0;
      //background: rgb(209, 233, 240);

      padding: 5px 3px;
      text-transform: uppercase;
      font-family: $fontFancy;
      .label-tick {
        background: rgb(134, 240, 134);
        border-radius: 50%;
        width: 17px;
        font-size: 10px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    input,
    select,
    textarea {
      width: 100%;
      font-size: 14px;
      font-weight: 100;
      padding: 6px 8px;
      background: white; //#ededed; //none; //rgb(235,235,235);
      border-radius: 0 3px 3px 3px;
      border: 0.5px solid rgb(189, 189, 189);
      outline: 0;
      font-family: $fontFancy2;
      z-index: 4;
      &[value=""] {
        opacity: 0.5;
      }
      &:disabled {
        background: none;
        border: none;
        font-size: 17px;
        padding: 4px 3px;
        color: brown;
      }
    }
    textarea {
      min-height: 100px;
      resize: none;
      box-sizing: border-box;
      max-width: 99%;
      font-size: 12px;
      line-height: 140%;
    }
    &:hover {
      label,
      input:focus + label {
        //font-weight: 700;
      }
    }
    .data-source-select {
      font-size: 12px;
      color: #5296b5;
      font-weight: bold;
      /* color: white; */
      box-shadow: 0 0 2px;
      padding: 0px 10px;
      border-radius: 10px;
      cursor: w-resize;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 900px) {
      justify-content: center;
      button {
        max-width: 480px;
      }
    }
  }
  button[type="submit"] {
    box-sizing: border-box;
    font-family: $fontFancy;
    font-size: 17px;
    position: fixed;
    /*display: grid;
    grid-template-columns: 40px 1fr;*/
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
      transition: 0.4s ease-in-out all;
      padding: 0 10px;
    }
    bottom: 10px;
    left: 50%;
    translate: -50% 0;
    width: 90%;
    margin-top: 14px;
    max-width: 200px;
    z-index: 5;
    //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.28);
    height: 50px;
    border-radius: 5px;
    background: rgb(209, 233, 240);
    &:hover {
      background: rgb(160, 182, 188);
      img {
        filter: invert(1);
      }
    }
  }

  .search-field {
    animation-name: appear;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    position: absolute;
    top: 100%;
    z-index: 5;
    font-size: 15px;
    background: #fafafa;
    box-shadow: 4px 3px 13px rgba(0, 0, 0, 0.3);
    max-height: 180px;
    overflow-y: auto;
    padding: 0px;
    min-width: 100%;
    border-radius: 0 0 10px 10px;
    div {
      font-family: $fontFancy2;
      cursor: pointer;
    }
    > div {
      //padding: 6px 9px;
      &:nth-child(2n + 1) {
        background: whitesmoke;
      }
      &:hover {
        background: #8ea39f6e;
      }
    }
    &.disabled-search-field {
      box-shadow: unset;
      opacity: 0.4;
      top: unset;
      bottom: 100%;
      right: 0;

      background: rgb(233, 233, 233);
      display: flex;
      flex-wrap: wrap;
      color: rgb(90, 90, 90);
      min-width: 50%;
      max-width: 70%;
      border-radius: 10px 10px 0 0;
      max-height: 30px;
      font-size: 11px;
      div {
        cursor: auto;

        min-width: max-content;
      }
      > div {
        &:nth-child(2n + 1) {
          background: initial;
        }
        &:hover {
          background: initial;
        }
      }
    }
  }
}

@keyframes jumpIn {
  0% {
    opacity: 0;
    rotate: x 90deg;
    //translate: 0 -10px;
  }
  100% {
    opacity: 1;
    rotate: x 0deg;
    //translate: 0 0px;
  }
}
.bag {
  perspective: 100px;
  perspective-origin: center;
  .bag-item {
    animation-name: jumpIn;
    animation-duration: 0.16s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
    background: white;
    padding: 3px 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 13px;
    font-weight: thin;
    margin: 3px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .arrows-order {
      font-size: 14px;
      opacity: 0.2;
      overflow: hidden;
      transition: 0.05s ease-in-out all;
      display: flex;
    }
    &:hover {
      .arrows-order {
        opacity: 1;
        > span:hover {
          color: orangered;
        }
      }
    }
  }
}

.create-done {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  padding: 30px;
  width: 100vw;
  height: 100vh;
  position: fixed;
  font-size: 25px;
  top: 0;
  left: 0;
  animation-name: appear;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  //z-index:5;
}

.search-window {
  position: absolute;
  top: 100%;
  max-height: 200px;
  background: rgb(241, 241, 241);
  overflow-y: scroll;
  border-radius: 5px;
  left: 0;
  width: 100%;
  min-width: 120px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.45);
  animation-name:appear;
  animation-duration:0.24s;
  animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1);
  animation-fill-mode: both;

  > a {
    text-decoration: none;
  }
  > div,
  > a > div {
    padding: 4px 6px;
    //padding:0;
    cursor: pointer;
    color: black;

    &:hover {
      background: rgb(96, 109, 117);
      color: white;
    }
  }
}

.filter-single {
  /*.search-window.active {
    display: none;
  }
  &:hover {
    .search-window.active {
      display: block;
    }
  }*/
}

.table-boxes {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  justify-items: space-between;

  * {
    font-family: $fontFancy2;
    font-size: 17px;
  }
  .table-box {
    padding: 10px;
    // background: white;
    border-radius: 5px;
    margin: 24px 32px;
    width: max-content;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    position: relative;
    min-width: 150px;
  }
  .table-box-heading {
    position: absolute;
    top: -15px;
    left: 0;
    color: rgb(71, 71, 71);
    max-width: max-content;
    padding: 3px 12px;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    background: white;
    font-size: 11px;
    margin-bottom: 9px;
  }
}

.remove {
  .remove-button {
    display: flex;
    justify-content: center;
    button {
      margin: 30px;
      padding: 10px;
      width: 200px;
    }
  }
  .title {
    font-family: $fontFancy;
    text-align: center;
    padding: 18px;
    margin: 20px;
  }
}

.active-order {
  box-shadow: 0 0 25px 25px #b3f7b395 inset;
}

.settlement-control {
  position: relative;
  .settlement-list-box {
    cursor: auto;
    position: absolute;
    display: none;
    top: 0px;
    left: 0px;
    background: whitesmoke;
    animation-name: appear;
    animation-duration: 0.24s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.23);
    font-weight: normal;
    font-size: 13px;
    max-height: 250px;
    width: 400px;
    overflow-y: scroll;
    border-radius: 3px;
    .summary {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      top: 24px;
      position: sticky;
      background-color: white;
      box-shadow: 0 2px 6px -3px rgba(0, 0, 0, 0.5);
      > div {
        padding: 0 2px;
      }
    }
    > .summary {
      padding: 5px 10px;
      top: 0;
      background-color: #178cb0;
      color: white;
      z-index: 3;
    }
    .settlement-list-group {
      > div {
        padding: 6px 9px;
        cursor: pointer;
        border-radius: 3px;

        &.item {
          display: grid;
          align-items: center;
          grid-template-columns: 22px 1fr 74px 30px;
          transition: 0.06s ease-in-out all;
          &:nth-child(2n + 1) {
            background: rgb(239, 239, 239);
          }
          .close {
            display: flex;
            justify-content: center;
            align-items: center;
            color: darkred;
            width: 25px;
            margin-left: 7px;
            height: 25px;
            transition: 0.3s ease-in-out all;
            &:hover {
              background: red;
              color: white;
              border-radius: 50%;
            }
          }
        }
        &.item:hover {
          background: #c2dbe7;
        }
      }
    }
  }

  &:hover {
    .settlement-list-box {
      display: block;
    }
  }
}

.full-settlement-editor {
  display: grid;
  grid-template-columns: 1fr 0px;
  transition: 0.13s ease-in-out all;
  &.divide {
    grid-template-columns: 1fr 350px;
  }
  .create-wrapper {
    .create-form form .section-group {
      grid-template-columns: 1fr !important;
    }
  }
  .settlement-list-box {
    position: relative;
    top: unset;
    left: unset;
    display: block;
    width: 100%;
    max-height: 90vh;
    //overflow-y: auto;
    font-size: 15px;
    * {
      font-family: $fontFancy2;
    }
    .settlement-list-group {
      .settle-button {
        margin-top: 5px;
        padding: 5px;
        background: rgb(168, 253, 168);
        border-radius: 5px;
        font-family: $fontFancy;
        &:hover {
          background: rgb(73, 157, 73);
        }
      }
      .item {
        padding: 9px 20px;
      }
      .item-details {
        display: grid;
        grid-template-columns: 160px 1fr 200px;
      }
      .summary {
        padding: 16px;
      }
    }
    .summary {
      padding: 15px 10px;
    }
  }
}

.group-heading-with-options {
  display: flex;
  margin-top: 7px;
  justify-content: space-between;
  .heading-options {
    display: flex;
    > div,
    > a {
      padding: 3px 7px;
      background: white;
      color: black;
      box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.24),
        0px 0px 6px rgba(0, 0, 0, 0.14);
      font-size: 14px;
      margin: 0 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 50%;
      // box-shadow:0 0 0px .5px white;
      img {
        width: 16px;
      }
      &:hover {
        background: grey;
        color: white;
        img {
          filter: invert(1);
        }
      }
    }
  }
  .settlement_comment {
    max-width: 500px;
    max-height: 36px;
    font-size: 9px;
    overflow-y: auto;
    margin: 0 10px;
  }
}

.latest-added-wrap {
  margin-left: 10px;
  //border-left: rgb(154, 238, 154) 13px solid;
  box-shadow: -24px 0px 0px -4px rgb(154, 238, 154);
  //border-radius: 15px 0 0 15px;
  //padding-left: 5px;
  //padding: 2px 9px;
}
.latest-added:not(:empty) {
  font-size: 9px;
  padding: 0px 3px;
  margin-top: 2px;
  border-radius: 4px;
  text-align: center;
  // box-shadow: 0 2px 3px -1px rgba(0, 0, 0, 0.1);
  .latest-added-label {
    font-size: 7px;
    color: grey;
  }
  .latest-added-date {
  }
}

.language-display {
  display: grid;
  padding-left: 3px;
  grid-template-columns: 20px 1fr;
  align-items: center;
  padding: 5px;
  &.reverse {
    grid-template-columns: 1fr 20px;

    :nth-child(2) {
      order: -1;
      padding-right: 5px;
      text-align: right;
    }
  }
}

.flag {
  width: 16px;
  border-radius: 1px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.08);
  margin-right: 5px;
}

.normal-table {
  $border: 0.5px solid rgb(201, 201, 201);
  border: $border;
  width: 100%;
  border-collapse: collapse;
  th {
    font-weight: 200;
    background: #d2e3e0;
  }
  tr:first-child {
    th {
      background: #c2d3d0;
      &:nth-child(2n + 1) {
        background: #b0c5c1;
      }
    }
  }
  td,
  th {
    border: $border;
    font-family: $fontFancy2;
    text-align: center;
  }
}

.sets {
  display: inline-block;
  margin-left: 32px;
}
.checkButtonWrap {
  display: flex;
  justify-content: center;
  position: relative;
  color: brown;
  align-items: center;

  cursor: pointer;
  .checkButton {
    border-radius: 50%;
    border: 1px solid grey;
    width: 22px;

    padding: 0;
    height: 22px;
    background: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      width: 15px;
      visibility: hidden;
    }
  }
  &:hover::after {
    position: absolute;
    left: 100%;
    animation-name: extend-right;
    animation-duration: 0.93s;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    font-size: 7px;
    content: " ▼";
  }
  .hoverShowWrapper {
    animation-name: appear-fancy;
    animation-duration: 0.1s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
    position: absolute;
    top: calc(95%);
    left: 32px;
    width: 75px;
    height: 25px;
    padding: 8px 12px;

    .hoverShow {
      background: white;
      position: absolute;
      left: 50%;
      translate: -50% 0;
      height: 15px;
      padding: 5px 0px;
      width: 80%;
      font-size: 12px;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
      border-radius: 5px;
      z-index: 2;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        translate: -50% 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 15px 7px 15px;
        filter: drop-shadow(0px -0.5px 0px rgba(0, 0, 0, 0.14));
        z-index: 1;
        border-color: transparent transparent white transparent;
      }
    }

    .hoverShow {
      &:hover {
        background: greenyellow;
        &::before {
          border-color: transparent transparent greenyellow transparent;
        }
      }
    }
  }
  .checkLabel {
    font-size: 12px;
    margin-left: 6px;
  }
  &.active {
    color: green;
    .checkButton img {
      visibility: visible;
      filter: drop-shadow(1px 1px green);
    }
  }
}

.write-in {
  animation-name: extend-right;
  animation-duration: 0.13s;
  animation-timing-function: ease-in;
  animation-fill-mode: both;
}

.settled-orders-list {
  position: relative;
  text-align: center;
}

.settled-orders-details {
  display: none;
  position: absolute;
  left: 64%;
  top: 90%;
  translate: -50% 0;
  max-width: 200px;
  max-height: 200px;
  overflow-y: scroll;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  //grid-template-columns: repeat(2, 1fr);
  z-index: 5;
}

.settled-orders-sum:hover ~ .settled-orders-details,
.settled-orders-details:hover {
  display: grid;
}
.customer-details-head {
  font-size: 15px;
  margin: 0 10px;
  position: relative;
  .customer-details-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 10;
    /*animation-name: appear-fancy-slight;
    animation-duration: 0.23s;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);*/
    .close-button {
      position: absolute;
      left: 96%;
      box-shadow: 0 0 2px;
      top: -5%;
      background: white;
      border-radius: 50%;
      width: 30px;
      font-size: 15px;
      color: cadetblue;
      line-height: 0;
      padding: 0;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: cadetblue;
        color: white;
      }
    }

    .customer-details {
      font-size: 14px;
      line-height: 150%;

      width: max-content;
      padding: 28px;
      border-radius: 5px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2), 0 0 60px rgba(0, 0, 0, 0.41);
      max-width: calc(min(500px, 80vw));
      height: max-content;
      max-height: 300px;
      background: white;
      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }
      overflow-y: auto;
    }
  }
}

.customer-details-in-editor {
  * {
    font-family: $fontFancy2;
    font-size: 15px;
  }
  div {
    padding: 9px;
  }
}

.executive-report-wrap {
  .exec-layout {
    display: grid;
    grid-template-columns: 0.9fr 1fr;
    align-items: flex-start;
    @media screen and (max-width: 950px) {
      grid-template-columns: 1fr;
      .detailed-order-report-editor {
        max-height: 40vh;
        overflow-y: auto;
      }
    }
  }

  .detailed-order-report-bar {
    background: rgb(229, 226, 226);
    * {
      font-family: $fontFancy;
    }
    padding: 5px 20px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 240px 1fr;
    button {
      max-height: 24px;
      padding: 3px;
      font-size: 11px;
    }
  }
  .pretty-table.orders-list-combined {
    font-size: 13px;
    max-width: 920px;
    margin: 5px auto;
    margin-bottom: 25px;

    td,
    th {
      width: auto;
    }
    td {
      text-align: left;
      padding: 3px 10px;
      &:empty {
        background: whitesmoke;
        &:nth-child(2n + 1) {
          background: whitesmoke;
        }
      }
      position: relative;
      .translated-details {
        display: none;
        grid-template-columns: 1fr 0.25fr;
      }
      &:hover {
        .translated-details {
          display: block;
          position: absolute;
          z-index: 3;
          align-items: center;
          grid-gap: 5px;
          top: 70%;
          left: 50%;
          width: 70px;
          border-radius: 0 10px 10px 10px;
          font-size: 12px;
          padding: 3px 9px;
          box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
          background: white;
          .translated-details-pages {
            display: grid;
            grid-template-columns: 1fr 12px 1fr;
            color: orangered;
          }
        }
      }
    }
    tr.sums {
      td {
        font-weight: 700;
        text-align: center;
      }
    }
    tr.selector-row {
      cursor: pointer;
      &:hover {
        z-index: 2;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        td,
        td:nth-child(2n + 1) {
          background: white;
        }
      }
      &.inactive {
        opacity: 0.3;
      }
    }
    * {
      font-family: $fontFancy2;
    }
  }
  .pretty-table.orders-list {
    width: 100%;
    td,
    th {
      width: auto;
      max-width: 250px;
      border: 1px solid grey;
    }
    td {
      padding: 0;
      vertical-align: baseline;
    }
  }
  .orders-listing {
    font-size: 13px;
    text-align: left;
    .orders-listing-single {
      display: grid;
      grid-template-columns: 120px 1fr 40px;
      font-family: $fontFancy2;
      padding: 3px 6px;
      &:nth-child(2n + 1) {
        background: rgb(229, 229, 229);
      }
      > div {
        //padding:3px 6px;
        text-align: left;
      }
    }
    @media screen and (max-width: 1100px) {
      font-size: 12px;
      .orders-listing-single {
        display: flex;
        justify-content: space-between;
        padding: 0;
        > div {
          padding: 0 2px;
          text-align: left;
        }
      }
    }
  }
}
