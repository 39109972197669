.documentEditor {
    padding:22px;
    font-size:15px;
    font-weight:100;
    
    display:grid;
    grid-template-columns: 360px 1fr;
    @mixin spacing {
        margin:8px 0;
    }
    .id {
        font-size:9px;
        color:grey;
        font-weight:100;
        a {
            padding:5px;
        }
    }
    .number {
        font-size:24px;
        @include spacing;
        letter-spacing: 1px;
        padding:5px 10px;
        background:whitesmoke;
        border-radius:5px;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        width:max-content;
    }
    .lang {
        display:flex;
        > div {
            padding:0 8px 0 0;
        }
    }
    .client {
        @include spacing;
        font-size:21px;
    }
    table.docEdit {
       // max-width:900px;
       border:none;
        width:100%;
        min-width:auto;
        @include spacing;
        thead {
            box-shadow:none;
            tr {
                border-top:1px solid lightgrey;
            }
        }
        th.controls {
            visibility:hidden;
        } 
        td.controls {
            opacity:0;
        }
        tr:hover {
            .controls {
                opacity:1;
            }
        }
        
        button.new {
            font-weight:800;
            font-size:25px;
            padding:2px 5px;
            width:80px;
            height:35px;
        }
        tr.blank {
            background-color:white;
            translate: 0 -4px;
            box-shadow:2px 2px 4px rgba(0,0,0,0.3);
        }
        tr.custom {
            * {
                color:rgb(46, 145, 99);
            }
        }
    }
    .documentData {
        max-height:88vh;
        overflow-y:auto;
    }
    .editableField {
        text-align: center;
        input, select, div {
            font-size:12px;
            
            background:none;
            border:none;
            padding:10px 3px;
            margin:0;
            width:100%;
            box-sizing:border-box;
            text-align:center;
        }
    }
  
}