@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear2 {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appearCol {
  0% {
    opacity: 0;
    height: 2px;
  }

  40% {
    opacity: 1;
  }
  90% {
    height: 100%;
  }
  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes highlight {
  0% {
    scale:1;
  }
  50% {
    scale:.98;
  }
  100% {
    scale:1;
  }
}

@keyframes boom {
  0% {
    filter: brightness(1);
    transform: rotate3d(0, 1, 0, 0deg) scale(1);
  }
  50% {
    filter: brightness(1.1);
    transform: rotate3d(0, 1, 0, -3deg) scale(1.05);
  }
  100% {
    filter: brightness(1);
    transform: rotate3d(0, 1, 0, 0deg) scale(1);
  }
}

@keyframes showUp {
  0% {
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: scale(0.76);
  }
  100% {
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(300px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slideIn2 {
    0% {
      transform: translateX(-50px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  

@media screen and (max-width: 900px) {
  @keyframes slideIn {
    0% {
      transform: translateY(200px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}

@keyframes loading {
  0% {
    transform: scale(0.6);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(0.6);
  }
}

@keyframes cropUp {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fly-in {
  0% {
    opacity: 0;
    transform: translateY(-70%);
  }
  70% {
    opacity: 0.7;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
