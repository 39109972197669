.ts-new-item-rev {
  .ts-new-buttons {
    display: flex;
    align-items: center;
    button {
      padding: 9px 16px;
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 30px;
      }
      &.active {
        font-weight: normal;
      }
    }
  }
  .action-types {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    box-shadow: 0 0px 1px rgba(0, 0, 0, 0.3) inset;
    //padding: 0 5px;
    .action-type:not(.active) {
      opacity: 0.14;

      padding: 12px 0;
      overflow: hidden;
    }
    &:hover {
      .action-type:not(.active) {
        opacity: 0.6;
      }
    }
  }

  .action-type {
    box-sizing: border-box;
    padding: 10px 5px 16px 5px;
    width: 100%;
    font-size: 12px;
    transition-duration: 0.2s;
    //background:rgba(200,200,200,0.03);
    //   opacity: 0.6;
    &:hover,
    &:hover:nth-child(2n + 2) {
      background: rgb(211, 235, 243);
      color: black;
    }
    &.active,
    &.active:nth-child(2n + 2) {
      //background:rgb(113, 119, 118);
      background: rgb(47, 79, 79);
      font-weight: normal;
      color: white;
      max-width: 300px;
      //scale:1.04;
      //text-shadow: 0 0 1px black;
      //border-radius:3px;
      opacity: 1;
    }
  }

  .other-types {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 3px 5px 8px;
    .action-type {
      &:not(.active) {
        background: rgb(250, 250, 250);
        color: black;
      }
      
      padding: 7px 4px 11px 3px;
      font-size: 12px;
    }
  }
  .text-types {
    display: grid;
    grid-template-columns: 120px 100px 100px 100px repeat(4, 1fr);
    //grid-gap:2px;
    //padding: 0px 5px;
    //padding:3px;

    .text-type:not(.active) {
      opacity: 0.14;
      padding: 12px 0;
      overflow: hidden;
    }
    &:hover {
      .text-type:not(.active) {
        opacity: 0.6;
        overflow: auto;
      }
    }
    button {
      width: 100%;
      box-sizing: border-box;
      padding: 12px 5px;
      //border-radius:5px;
      background: rgba(200, 200, 200, 0.03);
      font-size: 10px;
      opacity: 0.6;
      &:hover {
        background: rgb(218, 242, 249);
        color: black;
      }
      &.active {
        font-weight: normal;
        background: #b6cad1;
        color: black;
        opacity: 1;
      }
    }
  }
}

.TSforms .ts-new-item-rev .documents {
  margin-top: 0;
  .document {
    font-size: 12px;
    align-items: center;
    grid-template-columns: 30px 1fr 160px 80px 70px;
    opacity:0.75;
    div {
      font-family: $fontStandard;
    }
  }
}

.newTS .TSforms .document.selected {
  background-color: #c8e7d4;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.33) inset;
  opacity:1;
}

.newTS .TSforms .TScomments {
  font-size: 11px;
  height: 48px;
  outline: none;
  //width: calc(100% - 10px);
  //margin: 0 5px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 5px;
}

.newTS .TSforms .documents {
  padding: 1px;
}

.newTS .TSforms .document .docTranslators {
  padding: 3px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}
.newTS .TSforms .document .docTranslators div {
  animation-name: appear;
  animation-duration: 1.54s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  padding: 3px 7px;

  font-size: 11px;
  background: #809595;
  color: white;
}
.newTS .TSrowPreview .setTime input {
  font-size: 20px;
  width: 90px;
}

.newTS .TSrowPreview {
  padding: 3px 12px;
}

.TS-types {
  animation-name: appear;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-fill-mode: both;
  overflow: hidden;
}

.newTS .TSforms .document > div {
  padding: 2px 7px;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newTS .TSforms .documents {
  min-height: 35px;
}

.newTS .TSforms .document .docTranslators {
  padding: 0;
  > div.proofreading {
    background: white;
    color: slategrey;
    font-size: 10px;
    &::before {
      content: "SPR: ";
      display: "inline-block";
      margin-left: 4px;
      font-size: 8px;
      border-radius: 5px;
      padding: 1px 3px;
    }
  }
}
.externals-info {
  position: sticky;
  top: -3px;
  font-size: 13px;
  padding: 7px;
  background: #e8eef0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  align-items: center;
  > div {
    display: flex;
    > div {
      padding: 1px 6px;
      font-size: 11px;
    }
    flex-wrap: wrap;
  }
  .externals-info-date {
    font-size: 10px;
    padding: 3px 7px;
    display: inline-block;
    margin-left: 9px;
    background: white;
    box-shadow: 0 0 2px rgab(0, 0, 0, 0.3);
  }
}
.newTS .TSforms input.search-input {
  padding: 3px 10px;
  height: 28px;
  margin: 6px 3px;
  box-shadow:0 0 4px rgba(0,0,0,.3);
  border-radius: 8px;
}
.langButton {
  margin-top: 6%;
  height: 90%;
  width: 50px;
  border-radius: 10px 10px 0 0;

  img {
  }
  &.active {
    background: rgb(150, 183, 196);
    img {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    }
  }
}

.comment-row.additional-info {
  display: grid;
  grid-template-columns: 1fr 100px;
  .set-custom-language {
    padding: 0 5px;
    button {
      display: block;
      padding: 0;
      height: 44%;
      margin: 3% 0;
      width: 100%;
    }
  }
}
